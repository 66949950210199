
import React from "react"
//import Logo from "./icons/logo"
//import { Link } from "gatsby"
import Nav from "./Nav"

const Header = () => (
    <header
    style={{
        background: `var(--cardGray)`,
        }}
    >
        <Nav />        
    </header>
    
)



export default Header
