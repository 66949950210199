import React from "react"
import { FooterWrapper, FooterSocialWrapper, FooterSocialIconsWrapper } from "../elements"
import {useStaticQuery, Link, graphql} from "gatsby"

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            facebook: file(relativePath: { eq: "icon-facebook.svg"}) {
                publicURL
            }
            instagram: file(relativePath: { eq: "icon-instagram.svg"}) {
                publicURL
            }
            twitter: file(relativePath: { eq: "icon-twitter.svg"}) {
                publicURL
            }
            whatsapp: file(relativePath: { eq: "icon-whatsapp.svg"}) {
                publicURL
            }
        }
    `)
    return <FooterWrapper>
            <FooterSocialWrapper>
                <FooterSocialIconsWrapper>
                    <Link
                        to="https://www.facebook.com/KinesPeru"
                        target="_blank"
                        rel="noopener noreferrer"                    
                    >
                        <img src={data.facebook.publicURL} alt="facebook" />
                    </Link>
                    <Link
                        to="https://www.instagram.com/kinesiologas_extranjeras/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={data.instagram.publicURL} alt="instagram" />
                    </Link>
                    <Link
                        to="https://twitter.com/KineExtranjeras"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={data.twitter.publicURL} alt="twitter" />
                    </Link>
                    <Link
                        to="https://api.whatsapp.com/send?phone=+51945689142&amp;text=Hola%20quiero%20poner%20mi%20anuncio%20en%20*KINESIOLOGASEXTRANJERAS.COM*,%20me%20ayudas%20porfis%20;)"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={data.whatsapp.publicURL} alt="whatsapp" />
                    </Link>
                </FooterSocialIconsWrapper>
                <div className="credits">
                    © {new Date().getFullYear()} Kinesiólogas Extranjeras. <div>Todos los derechos reservados</div>
                </div>
            </FooterSocialWrapper>
        </FooterWrapper>
    
}

export default Footer
