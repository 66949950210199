import React from "react"
import PropTypes from "prop-types"


const H1 = ({ siteTitle }) => (  
    <h1 style={{
        margin: 0,
        paddingBottom: `15px`,
    }}>
        {siteTitle}
    </h1>
)

H1.propTypes = {
    siteTitle: PropTypes.string,
}

H1.defaultProps = {
    siteTitle: ``,
}

export default H1
