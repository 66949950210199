/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import H1 from "./h1"
import "./layout.css"
import Container from "./container"
import Footer from "./footer"
import Modal from "./modal"

//const checkbox = document.querySelector('#checkbox')


/*el.addEventListener('change', function (event) {
    if (this.checked) {
        document.body.classList.remove('is-light-mode')
        document.body.classList.add('is-dark-mode')
    } else {
        document.body.classList.remove('is-dark-mode')
        document.body.classList.add('is-light-mode')
    }
})
*/



//end darkmode

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `) 
    return (
    <>
      <Header />
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <main>
            <Container>
                <div className="main-title">
                    <div className="container">
                        <div className="main-title-grid">
                            <div>
                                <H1 siteTitle={data.site.siteMetadata.title} />
                                <h2>Bienvenidos a KinesiologasExtranjeras.com aquí encontrarás las más lindas Kinesiólogas, escorts, putas y modelos en Perú. 
                            Encuentra kinesiólogas venezolanas, colombianas, argentinas y más en un solo lugar.</h2>
                            </div>
                        </div>                    
                    </div>
                </div>
                {children}
            </Container>
        </main>
        <Footer />
        <Modal />        
      </div>
    </>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
