import React from "react"
import {useStaticQuery, Link, graphql} from "gatsby"
import { ModalWrapper } from "../elements"

const returnHome = () => {
	console.log('return')
    var x = document.getElementById("ke_modal");
        x.style.display="none";
        document.getElementsByTagName("body")[0].style.overflow = 'unset';

};

const Modal = () => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "desahuevate-conchatumare.jpg"}) {
                publicURL
            }
        }
    `)
    return <ModalWrapper>
        <div>
            <div className="ke-modal-content" id="ke_modal">
                <div className="ke-modal">                 
                    <img src={data.logo.publicURL} alt="desahuevate conchatumare" />
                    <h1>DESAHUEVATE CONCHATUMARE</h1>
                    <p>Este contenido es exclusivo de KinesiologasExtranjeras.com, prohibido copiar mrd, que chucha piensas que estás en el colegio?. Todos los derechos reservados.</p>
                    <p>¡GAAAAAAAAAAA!</p>
                    <Link 
                        to="/"
                        className="ext-join"
                        onClick={returnHome}
                    ><span>¡Kiero cachar ahora!</span></Link>
                </div>                
            </div>
        </div>
    </ModalWrapper>
}

export default Modal
