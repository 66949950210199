import React from "react"
import {useStaticQuery, Link, graphql} from "gatsby"
import { NavWrapper } from "../elements"


const Nav = () => {
    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: { eq: "svg/logo-kinesiologas-extranjeras.svg"}) {
                publicURL
            }
        }
    `)
    return <NavWrapper>
        <nav>
            <div className="container">
                <div className="header-logo">
                    <Link to="/" className="logo"><img src={data.logo.publicURL} alt="kinesiólogas extranjeras" /></Link>
                    <Link 
                        to="https://api.whatsapp.com/send?phone=+51945689142&amp;text=Hola%20quiero%20poner%20mi%20anuncio%20en%20*KINESIOLOGASEXTRANJERAS.COM*,%20me%20ayudas%20porfis%20;)"
                        className="ext-join"
                    ><span>Publicar anuncio</span></Link>
                </div>
            </div>
        </nav>
    </NavWrapper>
}

export default Nav
